<template>
  <div class="indexHome">
    <div class="banner">
      <div class="container">
        <img class="logoHome" src="/static/img/home_02.png" />
        <h1>
          可信科研过程管理工具<font style="letter-spacing: 0px"> —— </font
          >国内首个基于区块链研发的科学实验记录本
        </h1>
        <p>
          帮助科研人员及时记录科研实验，支持科研全过程可验证、可追溯、可审计。<br />
          用可信的数字实验记录代替纸质实验记录，帮助实验室规范化管理实验记录，推进科研资产确权管理<br />及科研诚信自查自检。
        </p>
        <div class="option">
          <a @click="openDialog" class="one" v-if="isTeamShow">团队预约使用</a>
          <a class="two"  href="/loadLogin">个人登录使用</a>
        </div>
      </div>
    </div>
    <div class="section section2">
      <div class="container">
        <div class="section-title">
          <strong>最高效的使用、最信任的过程</strong>
        </div>
        <div>
          <img src="/static/img/home_03.png" />
        </div>
      </div>
    </div>
    <div class="section section3">
      <div class="container">
        <div class="section-title">
          <strong>LabLink解决方案五大特性</strong>
        </div>
        <el-row :gutter="20">
          <el-col
            :lg="8"
            :xs="24"
            style="
              background: url(/static/img/home_04.png) center no-repeat;
              height: 360px;
            "
          >
            <div class="item">
              <h3 style="margin-top: 50px">高效</h3>
              <p>
                拥有"我的实验"、"项目管理"、"复核"、"分享"、"检索"、"用户管理"、"模版管理"和"实验审计"八大核心功能模块。通过自动化流程和丰富功能模块，提升研究所管理员、研究所审计员、研究所项目团队工作效率。
              </p>
            </div>
          </el-col>
          <el-col :lg="16" :xs="24">
            <el-row :gutter="10">
              <el-col :lg="8" :xs="24">
                <div class="item" style="background: #f6f7fb">
                  <h3>灵活</h3>
                  <p>
                    自由定义实验模版与专研领域组件，灵活支撑多领域实验记录。
                  </p>
                </div>
              </el-col>
              <el-col :lg="16" :xs="24">
                <div
                  class="item"
                  style="
                    background: url(/static/img/home_05.png) center no-repeat;
                  "
                >
                  <h3>合规</h3>
                  <p>
                    规范、电子签名、审计追踪、复核评议等多项合规技术帮助研究所项目团队满足NMPA等合规要求。
                  </p>
                </div>
              </el-col>
              <el-col :lg="16" :xs="24" style="margin-left: -3px">
                <div
                  class="item"
                  style="
                    background: url(/static/img/home_06.png) center no-repeat;
                  "
                >
                  <h3>安全</h3>
                  <p>
                    底层采用中国科学院自研区块链，安全可靠。
                    可选本地服务器部署，内网运行，数据存于机构内部，安全放心。
                    细粒度的用户权限设置与分配，严格的访问控制策略，防止数据失泄密。
                  </p>
                </div>
              </el-col>
              <el-col :lg="8" :xs="24">
                <div class="item" style="background: #f6f7fb">
                  <h3>可信</h3>
                  <p>
                    将身份、记录、操作数据上链存证，使实验记录防篡改可追溯。
                  </p>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="section section4">
      <div class="container">
        <div class="section-title">
          <strong>LabLink依托科创链实现实验记录存证</strong>
        </div>
        <div class="data">
          <p>科创链·ScienceChain是中国科学院文献情报中心在开源区块链组件RepChain的技术上研发的专门面向学术领域的区块链基础设施，其具有高度可定制性、可扩展性、安全性、隐私保护等优点。
            通过科创链，学者和研究机构可以更方便地进行学术成果的存储、管理与共享，同时也可以保护学术成果的知识产权。
          </p>
          <div class="option">
            <a class="one" href="https://sciencechain.ac.cn/" target="_blank"
              >了解科创链</a
            >
            <a href="https://sciencechain.ac.cn/realTimeNetwork" target="_blank"
              >验证实验记录</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="section section5">
      <div class="container">
        <div class="section-title"><strong>LabLink首批试点单位</strong></div>
        <div class="img">
          <img src="/static/img/home_08.png" />
        </div>
        <ul class="in-about-class clearfix">
          <li>
            <a>
              <div class="pic">
                <div class="bg"><em class="line"></em></div>
                <div class="icon">
                  <strong>中国科学院过程工程研究所</strong>
                </div>
                <img src="/static/img/img_ipe.png" />
              </div>
            </a>
          </li>

          <li class="mf">
            <a>
              <div class="pic">
                <div class="bg"><em class="line"></em></div>
                <div class="icon">
                  <strong>中国科学院青岛生物能源与过程研究所</strong>
                </div>
                <img src="/static/img/img_qibebt.png" />
              </div>
            </a>
          </li>
          <li>
            <a>
              <div class="pic">
                <div class="bg"><em class="line"></em></div>
                <div class="icon">
                  <strong>中国科学院重庆绿色智能院三峡生态环境研究所</strong>
                </div>
                <img src="/static/img/img_cigit.png" />
              </div>
            </a>
          </li>
        </ul>
        <div class="option">
            <a @click="openDialog">团队预约使用</a>
          </div>

      </div>
    </div>
    <div class="footer">
      <el-backtop style="border-radius: 50%"></el-backtop>
      <div class="footer-nav">
        <div class="container">
          <b><i class="el-icon-s-promotion blue1"></i> 友情链接</b>
          <ul style="display: inline">
            <li><a href="https://www.las.ac.cn/">中国科学院文献情报中心</a></li>
            <li>
              <a href="http://www.ipe.cas.cn/">中国科学院过程工程研究所</a>
            </li>
            <li>
              <a href="http://www.qibebt.cas.cn/"
                >中国科学院青岛生物能源与过程研究所</a
              >
            </li>
            <li>
              <a href="http://www.cigit.cas.cn/"
                >中国科学院重庆绿色智能技术研究院</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="container clearfix">
        <div class="footer-wrap">
          <div>
            <div>
              <h4>
                <i
                  class="el-icon-help blue1"
                  style="font-size: 22px; vertical-align: bottom"
                ></i>
                关于平台
              </h4>
              <ul>
                <li>运营单位: 中国科学院文献情报中心</li>
                <li>技术支持: 中国科学院文献情报中心知识系统部</li>
              </ul>
            </div>
            <div>
              <h4>
                <i
                  class="el-icon-chat-dot-round blue1"
                  style="font-size: 22px; vertical-align: bottom"
                ></i>
                联系我们
              </h4>
              <ul>
                <li>电话：010-82628382</li>
                <li>地址：北京市中关村北四环西路33号</li>
              </ul>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

            <!--
            <div class="hidden" >
              <ul>
                <li><a href="#">版权公告</a></li>
                <li><a href="#">使用协议</a></li>
                <li><a href="#">隐私声明</a></li>
                <li><a href="#">使用反馈</a></li>
              </ul>
            </div>
            <div class="line"></div>
            <div style="text-align: center" class="code">
              <img src="" style="width: 85px; height: 85px" />
              <p>关注公众号</p>
            </div>
             -->
          </div>
        </div>
      </div>
      <div class="footer-copy">
        <div class="container">
          京ICP备05002861号-53 版权所有©
          {{ currYear }} 中国科学院文献情报中心
        </div>
      </div>
    </div>
    <app-dialog ref="applyUseDialog" dialogTitle="预约申请" originTitle staticDialog width="600px">
            <el-form ref="form"  :rules="rules" :model="formItem" class="joinRuleForm">
              <div class="bd">机构信息</div>
                <el-form-item prop="insName" label="机构名称">
                    <el-input  v-model="formItem.insName"></el-input>
                </el-form-item>
                <el-form-item prop="workPlace" label="机构地址">
                    <el-input v-model="formItem.workPlace"></el-input>
                </el-form-item>
                <div class="bd">机构联系人信息</div>
                <el-form-item label="姓名" prop="userName">
                  <el-input v-model="formItem.userName"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                  <el-input v-model="formItem.phone"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="email">
                  <el-input v-model="formItem.email"></el-input>
                </el-form-item>
                <el-form-item prop="remarks" label="备注信息">
                        <el-input type="textarea"  v-model="formItem.remarks"></el-input>
                </el-form-item>
			      </el-form>
            <Vcode :show="isShow" @success="success" @close="close" class="vue-vcode" style="z-index: 2100;"  />
            <!-- :imgs="['/static/img/home_01.png', '/static/img/home_08.png']" -->
            <div class="save_btn_warp" style="text-align: center;">
                <el-button type="primary" style="width:50%;" size="medium" @click="saveData">提交</el-button>
            </div>
        </app-dialog>
  </div>
</template>
<script>
import{ClearUser} from "./../service/user";
    import { ApplyUseSave} from "./../service/applyUse"
    import Vcode from "vue-puzzle-vcode";
export default {
  name: "Index",
  data() {
    var checkPhone = (rule, value, callback) => {
      const regPhone = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
        if (regPhone.test(value)) {
          return callback();
        }
      callback(new Error('手机号码格式不正确'));
      };
      var checkEmail = (rule, value, callback) => {
      const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regEmail.test(value)) {
          return callback();
        }
      callback(new Error('电子邮箱格式不正确'));
      };
      return {
        isShow: false, // 验证码模态框是否出现
        isTeamShow:true,
        currYear: new Date().getFullYear(),
      checkEmail:checkEmail,
      checkPhone:checkPhone,
      formItem: {
        type:1,
          insName: '',
          workPlace: '',
          userName: '',
          phone:'',
          email:'',
          remarks:''
        },
     
				rules:{
          insName: [
            { required: true, message: '请输入机构名称', trigger: 'blur' }
          ],
          workPlace: [
            { required: true, message: '请输入机构地址', trigger: 'blur' }
          ],
          userName: [
            { required: true, message: '请输入联系人姓名', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入联系人手机号码', trigger: 'blur' },
            { validator: checkPhone, trigger: 'blur' }
          ],
          email: [
            { required: true, message: '请输入联系人电子邮箱', trigger: 'blur' },
            { validator: checkEmail, trigger: 'blur' }
          ],
                },
    };
  },
  components: {
    Vcode
  },
  created() {
    let domain=window.location.hostname
    if(domain=='lablink.ac.cn'||domain=='localhost'){
      this.isTeamShow=true;
    }else{
      this.isTeamShow=false;
    }
    this.clearUser()
  },
  methods:{
    clearUser(){
      if(this.user){
        ClearUser().then(e => {

      }).catch(e => {
      })
      }

    },
			openDialog(){
        this.formItem={}
        this.$refs.applyUseDialog.show();
			},
      saveData(){
        this.$refs['form'].validate(valid => {
            if (valid) {
              this.isShow=true;
            } else {
                setTimeout(() => {
                document.getElementsByClassName("is-error")[0].scrollIntoView({
                    behavior: "smooth",
                      block: "center"
                  });
            }, 0);
                return false;
            }
        })
      },
    // 用户通过了验证
    success(msg) {
      this.isShow = false; // 通过验证后，需要手动隐藏模态框 
      this.formItem.type=1
      ApplyUseSave(this.formItem).then(e => {
          let data = this.APIParse(e);
          if(data){
              this.$message.success('提交成功，请耐心等待，审核通过后将第一时间联系您。')
              this.$refs['form'].resetFields();
              this.$refs.applyUseDialog.hide();
          }
      }).catch(e => {
          this.$message.error(e.message)
      })
    },
    // 用户点击遮罩层，应该关闭模态框
    close() {
      this.isShow = false;
    }
}
}
</script>

<style scoped>
@import url("/static/css/index.css");
.x-body {
  overflow: auto !important;
}

</style>
<style>
.vue-vcode{
  z-index: 2100;
}
.vue-vcode .vue-auth-box_ .auth-control_ .range-box .range-slider {

background-color: rgba(61, 85, 167, 0.8);
}
.vue-vcode .vue-auth-box_ .auth-body_ .info-box_.fail {
    background-color: rgba(241, 64, 60, 0.8);
}
</style>